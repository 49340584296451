import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Service Box
import ServiceBox from "./service-box";

class Services extends Component {
  state = {
    services: [
      {
        title: "Enfoque en el Estudiante",
        icon: "pe-7s-user", // icono de usuario para enfoque en el estudiante
        description:
          "Ponemos al estudiante en el centro de todo lo que hacemos, asegurando que cada individuo reciba la atención y el apoyo necesarios para alcanzar sus metas.",
      },
      {
        title: "Diversidad e Inclusión",
        icon: "pe-7s-global", // icono de globo para diversidad e inclusión
        description:
          "Fomentamos un ambiente inclusivo donde se valoren y respeten todas las voces, promoviendo la diversidad como una fortaleza.",
      },
      {
        title: "Aprendizaje Continuo",
        icon: "pe-7s-study", // icono de libro para aprendizaje continuo
        description:
          "Creemos en la importancia del aprendizaje a lo largo de toda la vida y ofrecemos oportunidades para el desarrollo personal y profesional continuo.",
      },
      {
        title: "Facultad Experimentada",
        icon: "pe-7s-portfolio", // icono de portafolio para facultad experimentada
        description:
          "Contamos con un equipo docente altamente calificado y comprometido con la excelencia en la enseñanza y el aprendizaje.",
      },
      {
        title: "Prácticas y Pasantías",
        icon: "pe-7s-news-paper", // icono de maletín para prácticas y pasantías
        description:
          "Facilitamos oportunidades de prácticas y pasantías para que los estudiantes adquieran experiencia práctica y mejoren sus habilidades laborales.",
      },
      {
        title: "Cursos en Línea Flexibles",
        icon: "pe-7s-display1", // icono de pantalla para cursos en línea
        description:
          "Disponemos de cursos en línea flexibles que permiten a los estudiantes aprender a su propio ritmo y desde cualquier lugar.",
      },
      {
        title: "Actividades de Voluntariado",
        icon: "pe-7s-ribbon", // icono de cinta para actividades de voluntariado
        description:
          "Facilitamos actividades de voluntariado para que los estudiantes puedan participar activamente en la comunidad y desarrollar un sentido de responsabilidad social.",
      },
      {
        title: "Compromiso con la Comunidad",
        icon: "pe-7s-network", // icono de red para compromiso con la comunidad
        description:
          "Trabajamos activamente para tener un impacto positivo en nuestras comunidades locales y globales, fomentando el servicio y la responsabilidad social.",
      },
      {
        title: "Desarrollo Profesional Docente",
        icon: "pe-7s-tools", // icono de herramientas para desarrollo profesional
        description:
          "Proveemos programas de desarrollo profesional continuo para nuestro personal docente, asegurando que estén al día con las últimas metodologías y tecnologías educativas.",
      },
    ]

  };
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light bg-home-serv" style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', position: 'relative', overflow: 'hidden' }} id="services">
          <div className="bg-image">
            <div className="bg-overlay-serv"></div>
          </div>
          <Container style={{ position: 'relative', zIndex: 2 }}>
            {/* Render section title */}
            <SectionTitle
              title="Nuestros Servicios"
              description="En PREPA AVANTE ofrecemos una amplia gama de programas educativos diseñados para adaptarse a las necesidades de cada estudiante. Nuestro enfoque integral incluye el aprendizaje personalizado, cursos en línea, actividades de voluntariado y oportunidades de prácticas, asegurando una formación completa y enriquecedora para cada alumno."
            />

            <Row className="mt-5">
              {/* Render service box */}
              {this.state.services.map((service, key) => (
                <ServiceBox
                  key={key}
                  title={service.title}
                  icon={service.icon}
                  description={service.description}
                />
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Services;
