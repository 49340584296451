import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/swiper-bundle.css'; // Importar los estilos de Swiper



const Galeria = () => {
    // Array de imágenes para alimentar el Swiper
    const images = [
        'assets/images/prepa1.jpg',
        'assets/images/prepa2.jpg',
        'assets/images/prepa3.jpg',
        'assets/images/prepa4.jpg',
        'assets/images/prepa5.jpg',
        'assets/images/prepa6.jpg',
        'assets/images/prepa8.jpg',
        'assets/images/prepa9.jpg',
        'assets/images/prepa10.jpg',
        'assets/images/prepa11.jpg',
    ];
    // Estilos en línea para el componente
    const swiperStyles = {
        container: {
            width: '50%',
            height: '100%',
        },
        slide: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
        },
        img: {
            maxWidth: '45%',
            height: 'auto',
        },
    };

    return (
        <section id="galeria" style={{ padding: '80px 0', position: 'relative', marginBottom: '20px' }}>
            <div className="container">
                <div className="row">
                    <div className="col-sm-8 offset-sm-2">
                        <div className="section-title text-center" style={{ marginBottom: '50px' }}>
                            <h2>Galería de Imágenes</h2>
                        </div>
                    </div>
                </div>
                <div className="row" >
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        // spaceBetween={1}
                        slidesPerView={1}
                        navigation
                        pagination={{ clickable: true }}
                        scrollbar={{ draggable: true }}
                        style={swiperStyles.container}
                    >
                        {images.map((img, i) => (
                            <SwiperSlide key={i} style={swiperStyles.slide}>
                                <img src={`/${img}`} alt={`screenshot-${i}`} style={swiperStyles.img} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default Galeria;