import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

class FooterLinks extends Component {
  state = {
    socials: [
      { icon: "fa fa-brands fa-facebook", link: "https://www.facebook.com/profile.php?id=100088958686137" },
      { icon: "fa fa-brands fa-tiktok", link: "https://www.tiktok.com/@prepa_avante?is_from_webapp=1&sender_device=pc" },
      { icon: "fa fa-brands fa-instagram", link: "https://www.instagram.com/prepaavante7?igsh=MWtvYXFvNWcyNzUxYw==" },
      // { icon: "fa fa-brands fa-threads", link: "https://www.threads.net/@yosoylaquesana" },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <div className="footer-alt">
          <Container>
            <Row>
              <Col lg="12">
                <div className="float-sm-start pull-none">
                  <p>
                    &copy; Desarrollado por
                    <a href="https://tecnologiasadministrativas.com/" rel="nofollow" target="_blank">
                      {" "}TAE
                    </a>
                  </p>
                </div>
                <div className="float-sm-end pull-none copyright ">
                  <ul className="list-inline d-flex flex-wrap social">
                    {this.state.socials.map((social, key) => (
                      <li className="list-inline-item" key={key}>
                        <Link to={social.link} target="_blank" className="social-icon">
                          <i className={social.icon}></i>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                {/* <div className="clearfix"></div> */}
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FooterLinks;
