import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

class Features extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section" id="features">
          <Container>
            <Row className="align-items-center">
              <Col lg="5" className="order-2 order-lg-1">
                <div className="features-box mt-5 mt-lg-0">
                  <h3>
                    Una comunidad educativa dedicada a la transformación del aprendizaje
                  </h3>
                  <p className="text-muted web-desc">
                    Nos destacamos por brindar una educación que se adapta a cada estudiante, ofreciendo un ambiente de aprendizaje inclusivo y un enfoque constante en el desarrollo personal y profesional.
                  </p>
                  <div className="d-flex flex-wrap">
                    <div className="col-md-6">
                      <ul className="text-muted list-unstyled mt-4 features-item-list">
                        <li className="">
                          Enfoque en el Estudiante: Cada alumno recibe la atención y el apoyo necesarios para alcanzar sus metas.
                        </li>
                        <li className="">
                          Diversidad e Inclusión: Promovemos un ambiente donde todas las voces son valoradas y respetadas, viendo la diversidad como una fortaleza.
                        </li>
                        <li className="">
                          Aprendizaje Continuo: Fomentamos la educación a lo largo de toda la vida, con oportunidades de desarrollo personal y profesional.
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul className="text-muted list-unstyled mt-4 features-item-list">
                        <li className="">
                          Facultad Experimentada: Nuestro equipo docente está comprometido con la excelencia educativa y el crecimiento de los estudiantes.
                        </li>
                        <li className="">
                          Actividades de Voluntariado y Prácticas: Facilitamos oportunidades para que los estudiantes adquieran experiencia práctica y fomenten un sentido de responsabilidad social.
                        </li>
                        <li className="">
                          Cursos en Línea: Ofrecemos cursos flexibles para que los estudiantes aprendan a su propio ritmo desde cualquier lugar.
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <Link
                    to="#"
                    className="btn btn-primary mt-4 waves-effect waves-light"
                  >
                    Learn More <i className="mdi mdi-arrow-right"></i>
                  </Link> */}
                </div>
              </Col>
              <Col lg={{ size: 7, order: 2 }} xs={{ order: 1 }}>
                <div className="features-img mx-auto me-lg-0">
                  <img
                    src="assets/images/requisitos.jpg"
                    alt="prepaAVANTE"
                    className="img-fluid"
                    style={{
                      borderRadius: '15px', // Redondeo de esquinas
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)' // Sombreado de la imagen
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Features;
