import React from 'react';

const WhatsAppIcons = () => {
    // Números y enlaces de WhatsApp
    const pattyNumber = '528134566061';
    const alexaNumber = '528124198855';

    // Función para manejar el clic en el icono de Patty
    const handlePattyClick = () => {
        window.open(`https://wa.me/${pattyNumber}`, '_blank');
    };

    // Función para manejar el clic en el icono de Alexa
    const handleAlexaClick = () => {
        window.open(`https://wa.me/${alexaNumber}`, '_blank');
    };

    return (
        <div className="whatsapp-icons">
            {/* Icono de WhatsApp de Patty */}
            <div className="whatsapp-icon" onClick={handlePattyClick}>
                <i className="fa fa-whatsapp" aria-hidden="true"></i>
                <span className="tooltip">WhatsApp Patty (Titular)</span>
            </div>
            
            {/* Icono de WhatsApp de Alexa */}
            <div className="whatsapp-icon whatsapp-secondary" onClick={handleAlexaClick}>
                <i className="fa fa-whatsapp" aria-hidden="true"></i>
                <span className="tooltip">WhatsApp Alexa (Auxiliar)</span>
            </div>
        </div>
    );
};

export default WhatsAppIcons;
