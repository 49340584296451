import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Team Box
import TeamBox from "./team-box";

class AboutUs extends Component {
  state = {
    members: [
      {
        id: 1,
        name: "Liliana Flores",
        image: "assets/images/planEstudio.jpg",
        post: "Terapeuta de Sanación Física, Emocional y Espiritual",
      }
    ],
  };
  render() {
    return (
      <React.Fragment>
        <section className="section" id="about">
          <Container>
            <Row>
              <Col lg={{ size: 8, offset: 2 }}>
                <div className="about-title mx-auto text-center">
                  <h2 className="font-weight-light">
                    Una comunidad educativa dedicada a transformar el aprendizaje y el futuro de nuestros estudiantes
                  </h2>
                  <p className="text-muted pt-4">
                    En PREPA AVANTE, nos dedicamos a proporcionar una educación de alta calidad con un enfoque en la innovación y la excelencia. Ofrecemos programas diseñados para desarrollar habilidades prácticas y fomentar el pensamiento crítico, todo dentro de un ambiente inclusivo y de apoyo. Nuestra misión es transformar vidas a través del aprendizaje continuo, creando oportunidades que permitan a nuestros estudiantes alcanzar sus metas y sueños. Únete a nosotros y descubre un mundo lleno de posibilidades.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="mt-5 d-flex justify-content-center align-items-center">
              {/* Renderizar miembros del equipo */}
              {this.state.members.map((member, key) => (
                <div key={key} className="d-flex justify-content-center align-items-center">
                  <img
                    src="assets/images/planEstudio.jpg"
                    alt={member.image}
                    className="img-fluid"
                    style={{
                      borderRadius: '15px', // Redondeo de esquinas
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)' // Sombreado de la imagen
                    }}
                  />
                </div>
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default AboutUs;
