import React, { Component } from 'react';
import NavbarPage from "../../components/Navbar/NavbarPage";
import Section from "./section";
import Features from '../../components/Features/features';
import Services from '../../components/Services/services';
import AboutUs from '../../components/About Us/about-us';
import ContactUs from '../../components/Contact Us/contact-us';
import Footer from '../../components/Footer/footer';
import Galeria from '../../components/galeria/galeria';

class Index2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navClass: ""
        };
    }
    componentDidMount() {
        document.getElementById("colorTheme").href = "assets/colors/red.css";
    }


    render() {
        return (
            <React.Fragment>
                <NavbarPage navClass={this.state.navClass} />
                <Section />
                <Features />
                <Services />
                <AboutUs />
                <Galeria />
                <ContactUs />
                <Footer />
            </React.Fragment>
        );
    }
}

export default Index2;