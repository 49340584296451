import React from "react";
import { useState } from "react";
import { Container, Row, Col, Button, FormFeedback, Form, Input } from "reactstrap";
import axios from 'axios';
import Swal from 'sweetalert2'
import ReactLoading from 'react-loading';


// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Section Title
import SectionTitleContact from "../common/section-title-contact";

const ContactUs = () => {
  const [cargando, setCargando] = useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: '',
      email: '',
      subject: '',
      comments: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Por favor, ingresa tu nombre"),
      email: Yup.string().required("Por favor, ingresa tu correo electrónico"),
      comments: Yup.string().required("Por favor, ingresa el asunto"),
    }),
    onSubmit: (values) => {
      setCargando(true)
      console.log("values", values);
      const nombre = values.name;
      const correo = values.email;
      const mensaje = values.comments;
      //Cambiar estos datos para cada pagina y su respectivo contacto
      const nombreProp = 'PREPA AVANTE';
      const correoProp = 'admin@prepaavante.com';
      const pagina = 'prepaavante.com';

      // Crear un objeto con los datos a enviar
      const data = {
        nombre,
        correo,
        mensaje,
        nombreProp,
        correoProp,
        pagina
      };

      console.log(data)
      // Realizar la solicitud POST utilizando Axios
      axios.post('https://taeconta.com/api/public/api/correos/publicos', data)
        .then(response => {
          console.log('Correo enviado con éxito:', response.data);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Correo enviado exitosamente",
            showConfirmButton: false,
            timer: 1500
          });
          setCargando(false)
          // clearState(e)
        })
        .catch(error => {
          console.error('Error al enviar el correo:', error);
          Swal.fire(
            "Error al enviar el correo",
            error.message + ", " + error.response.data.message,
            "error"
          );
          setCargando(false)
          // clearState(e)
        });
    }
  });


  return (
    <React.Fragment>
      <section className="section " id="contact">
        <Container>
          {/* Render section title */}
          <SectionTitleContact
            title="Contáctanos"
            description="Estamos aquí para ayudarte a avanzar en tu educación. En PREPA AVANTE, ofrecemos programas educativos que se adaptan a tus necesidades, ya sea en línea o presencial. Nuestro equipo está listo para resolver tus dudas y acompañarte en cada paso hacia tu futuro académico. ¡Contáctanos hoy mismo y comienza a transformar tu vida!"
          />

          <Row>
            <Col lg="4">
              <div className="mt-4 pt-4">
                <p className="mt-4">
                  <span className="h5">Dirección del Consultorio:</span>
                  <br />{" "}
                  <a
                    href="https://maps.app.goo.gl/nyLpBF8UCuXFjD4W6"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-muted d-block mt-2"
                  >
                    Av. Miguel Alemán #211 Col. Linda Vista en Guadalupe N.L. C.P. 67130
                  </a>
                </p>
                <p className="mt-4">
                  <span className="h5">Horario de Atención:</span>
                  <br />{" "}
                  <span className="text-muted d-block mt-2">
                    Lunes a Viernes: 9:00 AM - 6:00 PM <br />
                    Sábados: 9:00 AM - 5:00 PM <br />
                    Domingos: 9:00 AM - 2:00 PM
                  </span>
                </p>
                <p className="mt-4">
                  <span className="h5"> Numeros de contacto: </span>
                  <span>
                    <a href="tel:+528134566061" className="text-muted d-block ">Patty (Titular) - 81 3456 6061</a>
                    <a href="tel:+528124198855" className="text-muted d-block ">Alexa (Auxiliar) - 81 2419 8855</a>
                    <a href="tel:+528181207486" className="text-muted d-block ">Valeria (Auxiliar) - 81 8120 7486</a>
                    <a href="tel:+528181207276" className="text-muted d-block ">Fernanda (Auxiliar) - 81 8120 7276</a>
                  </span>
                </p>
              </div>
              
            </Col>
            <Col lg="8">
              <div className="custom-form mt-4 pt-4">
                <p id="error-msg"></p>
                <div id="message"></div>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}>
                  <Row>
                    <Col lg="6 mt-2">
                      <Input
                        name="name"
                        className=""
                        placeholder="Tu nombre"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                          validation.touched.name && validation.errors.name ? true : false
                        }
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                      ) : null}
                    </Col>
                    <Col lg="6 mt-2">
                      <Input
                        name="email"
                        className=""
                        placeholder="Tu correo"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          validation.touched.email && validation.errors.email ? true : false
                        }
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12 mt-2">
                      <Input
                        name="subject"
                        className=""
                        placeholder="Asunto"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.subject || ""}
                        invalid={
                          validation.touched.subject && validation.errors.subject ? true : false
                        }
                      />
                      {validation.touched.subject && validation.errors.subject ? (
                        <FormFeedback type="invalid">{validation.errors.subject}</FormFeedback>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12 mt-2">
                      <div className="form-group">
                        <textarea
                          name="comments"
                          id="comments"
                          rows="4"
                          className="form-control"
                          placeholder="Tu mensaje..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.comments || ""}
                        ></textarea>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" className="text-end">
                      <Button className="submitBnt btn btn-primary">
                        {!cargando ? 'Enviar mensaje' : <ReactLoading type="cylon" width={50} />}
                      </Button>
                      <div id="simple-msg"></div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
            <div className="embed-responsive embed-responsive-16by9 mt-2">
                {/* Mapa de Google */}
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3595.421288063277!2d-100.2529806!3d25.6904719!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662ebc9648305b7%3A0xc732017334441452!2sPrepa%20Avante%20Unidad%20Linda%20Vista!5e0!3m2!1ses-419!2smx!4v1729787642908!5m2!1ses-419!2smx"
                  width="100%"
                  height="200px"
                  className="embed-responsive-item "
                  style={{ borderRadius: '12px',  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)'}}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default ContactUs;
